import React, { Component } from 'react'
import Layout from '@components/layout/Layout'
import ReferenceComponent from '@components/references/reference.component'
import ReadNext from '@components/read-next-button/read-next-button'
import { references } from '@components/references/high-sodium-intake-references'
import { liveRampEventTracking } from '../../../app-utils'
import { Link } from 'gatsby'

import './high-sodium-intake.scss'

class HighSodiumIntake extends Component {
  constructor(props) {
    super(props)
  }

  componentDidMount() {
    liveRampEventTracking('high-sodium-intake-page-view')
  }

  render() {
    const readNext = {
      readNextContent: 'Psychiatric and sleep comorbidities',
      goTo: '/comorbidities-risk/psychiatric-and-sleep-comorbidities/',
      tracking: 'comorbidities,	click, psychiatric and sleep comorbidities - comorbidities',
    }

    return (
      <Layout addedClass='page-high-sodium-intake' location={this.props.location}>
        <div className="high-sodium-intake-page-header " role="img" alt="Cloudy open terrain path with boulders">
          <div className="primary-container">
            <div className="high-sodium-intake-header-text">
              <h1>Explore the Effects of High Sodium Intake on Body Systems</h1>
              <h3>Certain comorbidities are more prevalent in patients with narcolepsy compared with the general population, including <Link to={'/comorbidities-risk/cardiovascular-comorbidities/'}>CV&nbsp;diseases</Link>.<sup>1,2</sup> Some risk factors for CV diseases are <Link to={'/comorbidities-risk/risk/'}>modifiable</Link>,<sup>3-12</sup> including <Link to={'/comorbidities-risk/risk/#HighSodiumIntake'}>sodium intake</Link>.<sup>4</sup></h3>
              <h3>Did you know that the impact of chronic high dietary sodium intake may go beyond effects on blood pressure? Elevated dietary sodium can affect the heart, arteries, kidney, brain, and maybe even the bones, and it may contribute to disease development over time.<sup>13,14</sup></h3>
            </div>
          </div>
        </div>
        <div className="background-white">
          <section className="section-gradient" />
        </div>
        <div className="primary-container">
            <h3 className="info-section-header">
                Explore each body system to learn more about sodium’s potential impacts
            </h3>
            <div className='iframe-container'>
              <div style={{ padding: '56.25% 0 0 0', position: 'relative' }}>
                <iframe src="https://player.vimeo.com/video/911364050?badge=0&autopause=0&player_id=0&app_id=58479" frameborder="0" allow="autoplay; fullscreen; picture-in-picture; clipboard-write" title="Jazz Sodium Experience" style={{ position: 'absolute', top: '0', left: '0', width: '100%', height: '100%' }}></iframe>
              </div>
              <script src="https://player.vimeo.com/api/player.js"></script>
            </div>
        </div>
        <div className="primary-container">
          <ReadNext readNext={readNext} />
        </div>
        <ReferenceComponent referenceList={references} />
      </Layout>
    )
  }
}

export default HighSodiumIntake
